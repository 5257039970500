import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Icon, Modal, SvgIcon, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { Icons } from '@/components/constants/Icons';
import AllPitchesTable from '@/components/tables/AllPitchesTable';
import usePendingPitchesStats from '@/hooks/metrics/usePendingPitchesStats';
import usePlaylists from '@/hooks/playlists/usePlaylists';
import { PitchTrackModel } from '@/models/TrackInterfaces';

import PlaylistAccordion from '../components/cards/PlaylistAccordion';
import FeedbackModal from '../components/modals/FeedbackModal';
import Loading from '../components/utility/Loading';
import { PlaylistSelectionModel, UnhurdPlaylist } from '../models/PlaylistInterface';

export default function AllPlaylistPitchesPage() {
  document.title = 'Playlist Pitches';
  const navigate = useNavigate();
  const [toggleValue, setToggleValue] = useState<string>(localStorage.getItem('playlist-pitches-toggle') || 'card');
  const [allPlaylists, setAllPlaylists] = useState<UnhurdPlaylist[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isLoadingPlaylists, setIsLoadingPlaylists] = useState<boolean>(true);
  const [playlistSelections, setPlaylistSelections] = useState<PlaylistSelectionModel[]>([]);
  const [trackSelections, setTrackSelections] = useState<PitchTrackModel[]>([]);
  const [accept, setAccept] = useState<boolean>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { pendingPitches } = usePendingPitchesStats();
  const { playlists, totalPlaylists, totalPages, refetchPlaylists } = usePlaylists({
    pageNumber: pageNumber,
    status: 'pending-pitches',
    sortBy: 'pending-pitches',
    orderBy: 'desc',
  });

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const tracks: PitchTrackModel[] = [];
    playlistSelections.forEach((item: PlaylistSelectionModel) => {
      item.tracks.forEach((track: PitchTrackModel) => {
        tracks.push(track);
      });
    });
    setTrackSelections(tracks);
  }, [playlistSelections]);

  const canPage = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (!totalPages || !playlists || !totalPlaylists) return;
      return entries[0].isIntersecting && !isLoadingMore && allPlaylists.length < totalPlaylists;
    },
    [totalPages, playlists, isLoadingMore, totalPlaylists, allPlaylists]
  );

  useEffect(() => {
    if (!playlists) return;
    setAllPlaylists((allPlaylists) => [...allPlaylists, ...playlists]);
    setIsLoadingPlaylists(false);
    setIsLoadingMore(false);
  }, [playlists]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
      if (canPage(entries) && totalPlaylists && allPlaylists.length < totalPlaylists) {
        setIsLoadingMore(true);
        setPageNumber(pageNumber + 1);
      } else {
        setIsLoadingMore(false);
      }
    }, {});
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [canPage, pageNumber, totalPages, totalPlaylists, allPlaylists]);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleToggle = (_: React.MouseEvent<HTMLElement>, value: string) => {
    localStorage.setItem('playlist-pitches-toggle', value);
    setToggleValue(value);
  };

  const updateSelections = useCallback((tracks: PitchTrackModel[], playlist: UnhurdPlaylist) => {
    const playlistSelectionItem = {
      tracks: tracks,
      playlistID: playlist.id,
    };
    setPlaylistSelections((prev) => {
      const index = prev.findIndex((item: PlaylistSelectionModel) => item.playlistID === playlist.id);
      if (index === -1) {
        return [...prev, playlistSelectionItem];
      } else {
        const newPlaylistSelections = prev.filter((item: PlaylistSelectionModel) => item.playlistID !== playlist.id);
        newPlaylistSelections.push(playlistSelectionItem);
        return newPlaylistSelections;
      }
    });
  }, []);

  const handleSelections = useCallback((tracks: PitchTrackModel[]) => {
    setTrackSelections(tracks);
  }, []);

  return (
    <div className="page-content">
      <div className="d-flex">
        <div>
          <p
            className="text-faded cursor-pointer d-flex"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Icon>chevron_left</Icon>
            <span className="pt2">Dashboard</span>
          </p>
          <h2 data-testid="pending-pitches-title">All pending pitches</h2>
          <p className="mt16 mb16 text-faded">
            You can review all of your pending pitches below and decide whether you want to either reject them or accept
            them onto your playlist.
          </p>
        </div>
      </div>
      <div className="d-flex mb16">
        <h3 className="mt-auto mb-auto" data-testid="playlist-pending-pitches">
          Pending pitches: {pendingPitches}
        </h3>
        <ToggleButtonGroup className="ml-auto mt-auto mb-auto" exclusive value={toggleValue} onChange={handleToggle}>
          <ToggleButton value="card">
            <SvgIcon component={Icons.card} />
          </ToggleButton>
          <ToggleButton value="list">
            <SvgIcon component={Icons.list} />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {!isLoadingPlaylists &&
        toggleValue === 'card' &&
        allPlaylists?.map((playlist: UnhurdPlaylist, index: number) => (
          <div
            data-testid={`playlist-item-${index}`}
            key={playlist.id}
            ref={index === allPlaylists.length - 1 ? ref : null}
          >
            <PlaylistAccordion
              playlist={playlist}
              expanded={index === 0}
              setMultipleSelectedTracks={updateSelections}
            />
          </div>
        ))}
      {toggleValue === 'list' && <AllPitchesTable outputSelectedTracks={handleSelections} />}
      {isLoadingPlaylists && toggleValue === 'card' && (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
      {isLoadingMore && <div className="text-center">Loading more...</div>}
      {trackSelections.length > 0 && (
        <div className="playlist-select-footer d-flex">
          <div className="mt-auto mb-auto">
            {' '}
            <p>
              {trackSelections.length} {trackSelections.length === 1 ? 'pitch' : 'pitches'} selected
            </p>
          </div>
          <div className="ml-auto">
            <Button
              className="border-btn"
              onClick={() => {
                setAccept(false);
                setModalOpen(true);
              }}
            >
              <span className="btn-text icon-suffix">Reject</span>
              <Icon>close</Icon>
            </Button>
            <Button
              onClick={() => {
                setAccept(true);
                setModalOpen(true);
              }}
              className="btn-white"
            >
              <span className="btn-text icon-suffix">Accept</span>
              <Icon>done</Icon>
            </Button>
          </div>
        </div>
      )}
      <Modal open={modalOpen} onClose={handleModalClose}>
        <React.Fragment>
          <FeedbackModal
            tracks={trackSelections}
            accept={accept}
            closeModalOutput={setModalOpen}
            updateTracks={() => {
              setIsLoadingPlaylists(true);
              setPlaylistSelections([]);
              setPageNumber(1);
              refetchPlaylists();
            }}
          ></FeedbackModal>
        </React.Fragment>
      </Modal>
    </div>
  );
}
